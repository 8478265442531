<template>
  <v-card class="certification-upload">
    <div class="certification-provider">
      <div class="provider-header">
        <img src="https://www.microsoft.com/favicon.ico" width="50" height="50" />
        <label class="certification-provider-name">Upload Microsoft Certifications</label>
      </div>
      <v-btn
        :color="colors.PRIMARY"
        class="white--text certification-selection-btn"
        @click="selectFile()"
        v-if="!isLoading"
      >Select CSV</v-btn>
      <v-progress-circular v-else :color="colors.PRIMARY" indeterminate :size="48" :width="3"></v-progress-circular>
      <input
        v-show="false"
        id="certFileUpload"
        type="file"
        accept=".csv"
        @change="uploadFile($event.target.name, $event.target.files)"
      />
      <v-snackbar
        v-model="snackbarShown"
        :timeout="snackbarTimeout"
        :color="snackbarColor"
      >{{snackbarMsg}}</v-snackbar>
    </div>
  </v-card>
</template>

<script>
import colors from "@/colors";
import certificationDataAccess from "../../../dataAccess/certificationDataAccess";

export default {
  name: "certificationupload",
  data() {
    return {
      colors,
      isLoading: false,
      certDAO: new certificationDataAccess(),
      snackbarTimeout: 5000,
      snackbarShown: false,
      snackbarMsg: '',
      snackbarColor: ''
    };
  },
  methods: {
    selectFile() {
      document.getElementById("certFileUpload").click();
    },
    async uploadFile(name, files) {
      this.isLoading = true;
      try{
        await this.certDAO.uploadMicrosoftCerts(files[0]);
        document.getElementById("certFileUpload").value = "";
      }catch(err){
        this.isLoading = false;
        this.snackbarColor = 'red';
        this.snackbarMsg = 'Error uploading certifications, please make sure the uploaded file is valid.';
        this.snackbarShown = true;
        document.getElementById("certFileUpload").value = "";
        throw err;
      }

      await this.refreshCerts();
    },
    async refreshCerts(){
      try{
        certificationDataAccess.lastGetCertificationsUpdate = -1;
        await this.certDAO.getCertifications('');
      } catch(err){
        this.isLoading = false;
        this.snackbarColor = 'red';
        this.snackbarMsg = 'Error loading certifications.';
        this.snackbarShown = true;
        throw err;
      }

      this.snackbarColor = 'green';
      this.snackbarMsg = 'Certification upload successful.';
      this.snackbarShown = true;
      this.isLoading = false;
    }
  }
};
</script>

<style scoped>
.certification-upload {
  margin: 20px;
  max-width: 1200px;
}

.certification-provider {
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.provider-header {
  display: flex;
  align-items: center;
  justify-content: space-around;
  margin-bottom: 10px;
}

.certification-provider-name {
  margin-left: 20px;
  font-size: 16pt;
}
</style>