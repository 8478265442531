<template>
  <div>
    <div class="title-bar">
      <h1>Certification Management</h1>
    </div>
    <CertificationUpload/>
    <CertificationList/>
  </div>
</template>

<script>
import CertificationUpload from "./components/CertificationUpload.vue"
import CertificationList from "./components/CertificationList.vue"

export default {
  name: "certificationmanagement",
  components: {
    CertificationUpload,
    CertificationList
  }
}
</script>