import apiWrapper from "@/apiWrapper";
import store from "@/store";

class certificationDataAccess {
  static cacheTimeoutMilliseconds = 1000 * 60 * 5;
  static lastGetCertificationsUpdate = -1;
  static lastCertificationUser = "";

  async uploadMicrosoftCerts(csvFile){
    var csvBase64 = await this.getCsvBytes(csvFile);
    await apiWrapper.uploadMicrosoftCerts(csvBase64);
  }

  async getCsvBytes(csvFile){
    var fileReadPromise = new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(csvFile);
      reader.onload = () => resolve(reader.result);
      reader.onerror = error => reject(error);
    });

    return await fileReadPromise;
  }

  async getCertifications(userId){
    if(this.needsCertificationUpdate(userId)){
      var result = await apiWrapper.getCertifications(userId);
      store.commit("setCertifications", result.data);
      certificationDataAccess.lastGetCertificationsUpdate = new Date().getTime();
      certificationDataAccess.lastCertificationUser = userId;
    }

    return store.getters.certifications;
  }

  needsCertificationUpdate(userId){
    return userId != certificationDataAccess.lastCertificationUser ||
           certificationDataAccess.lastGetCertificationsUpdate === -1 ||
           new Date().getTime() - certificationDataAccess.lastGetCertificationsUpdate >= certificationDataAccess.cacheTimeoutMilliseconds;
  }
}

export default certificationDataAccess